import React, { useEffect, useRef, useState } from 'react';
import ModuleNavigation from './ModuleNavigate';
import progress3 from "../../static/img/class-progress-icon-3.svg"
import progress1 from "../../static/img/class-progress-icon-1.svg"
import ProgressIcon3 from '../../static/img/class-progress-icon-3.svg';
import ProgressIcon4 from '../../static/img/class-progress-icon-4.svg';
import ProgressIcon2 from '../../static/img/class-progress-icon-2.svg';
import Module from './widgets/Module'
import { getCourse, setViewLesson } from '../Api/course'
import Player from '@vimeo/player';
import { useDispatch, useSelector } from 'react-redux';
import { selectLessonNumber, selectModuleNumber, selectModules, selectSelectedLesson, selectUserId, setSelectedLesson } from '../Login/loginSlice';
import VideoPlayer from './VideoPlayer'

let lesson_number = 0
let module_number = 0
function ClassRoom() {
    const dispatch = useDispatch()
    const user_id = useSelector(selectUserId)
    const modules = useSelector(selectModules);
    const selectedLesson = useSelector(selectSelectedLesson)



    const moduleTitle = "Module 1: Introduction to Carbon Neutrality";
    const lessons = [
        "Introduction to carbon neutrality and climate change",
        "Understanding Carbon Neutrality",
        "The Importance of Carbon Neutrality",
        "Implementing Carbon Neutrality in Organizations"
    ];
    const progressIcons = {
        titleIcon: ProgressIcon3,
        openIcon: progress1,
        closedIcon: ProgressIcon2,
        lessonIcon: ProgressIcon3,
        emptyIcon: ProgressIcon4
    };

    const handleLessonClick = (videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid) => {
        console.log('handleLessonClick', videoId)

        // var options01 = {
        //     url: `https://player.vimeo.com/video/${videoId}?h=${videoH}`
        // };

        // var video01Player = new Player(iframeRef.current, options01);
        // const handlePlay = () => {
        //     console.log('Played the video', lesson_number, module_number);
        //     setViewLesson(user_id, lesson_number, module_number);
        // };

        // video01Player.on('play', handlePlay);


        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        // setTimeout(() => setEventPlay(), 1000)
        lesson_number = LessonNumber
        module_number = ModuleNumber
    };

    const handlePastLesson = () => {
        console.log('past', selectedLesson)
        let LessonNumber = selectedLesson.LessonNumber
        let ModuleNumber = selectedLesson.ModuleNumber
        if (ModuleNumber === 1) {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
        }
        else if (ModuleNumber === 2) {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
            else {
                ModuleNumber = 1
                LessonNumber = 4
            }
        }
        else {
            if (LessonNumber > 1) LessonNumber = LessonNumber - 1
            else {
                ModuleNumber = 2
                LessonNumber = 3
            }
        }
        const videoId = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_id
        const videoH = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_h
        const moduleName = modules[ModuleNumber - 1].moduleName
        const lessonName = modules[ModuleNumber - 1].lessons[LessonNumber - 1].name
        const gid = modules[ModuleNumber - 1].lessons[LessonNumber - 1].gid
        console.log(modules[ModuleNumber - 1].lessons[LessonNumber])
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        lesson_number = LessonNumber
        module_number = ModuleNumber
    }

    const handleNextLesson = () => {
        console.log('next', selectedLesson)
        let LessonNumber = selectedLesson.LessonNumber
        let ModuleNumber = selectedLesson.ModuleNumber
        if (ModuleNumber === 2) {
            if (LessonNumber < 3) LessonNumber = LessonNumber + 1
            else {
                ModuleNumber = 3
                LessonNumber = 1
            }
        }
        else {
            if (LessonNumber < 4) LessonNumber = LessonNumber + 1
            else if (ModuleNumber === 1) {
                ModuleNumber = 2
                LessonNumber = 1
            }
        }
        const videoId = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_id
        const videoH = modules[ModuleNumber - 1].lessons[LessonNumber - 1].video_h
        const moduleName = modules[ModuleNumber - 1].moduleName
        const lessonName = modules[ModuleNumber - 1].lessons[LessonNumber - 1].name
        const gid = modules[ModuleNumber - 1].lessons[LessonNumber - 1].gid
        console.log(modules[ModuleNumber - 1].lessons[LessonNumber])
        dispatch(setSelectedLesson({ videoId, videoH, moduleName, lessonName, LessonNumber, ModuleNumber, gid }));
        lesson_number = LessonNumber
        module_number = ModuleNumber
    }

    const iframeRef = useRef(null);

    // useEffect(() => {
        // var iframe = document.querySelector('iframe');

        // var player = new Player(iframe);
        // console.log('useeffect play', player.element)
        // player.on('play', function () {
        //     console.log('Played the video', lesson_number, module_number);
        //     setViewLesson(user_id, lesson_number, module_number)
        // });

        // player.getVideoTitle().then(function (title) {
        //     console.log('title:', title);
        // });

    //     return () => {
    //         player.off('play', function () {
    //             console.log('Played the video', lesson_number, module_number);
    //             setViewLesson(user_id, lesson_number, module_number)
    //         });
    //     };
    // }, [selectedLesson]);

    // function setEventPlay() {
    //     const iframe = document.getElementById('123')
    //     if (!iframe) return;

    //     const player = new Player(iframe);

    //     console.log('setEventPlay', player);

    //     const handlePlay = () => {
    //         console.log('Played the video', lesson_number, module_number);
    //         setViewLesson(user_id, lesson_number, module_number);
    //     };

    //     // player.on('play', handlePlay);
    //     player.on('ready', function() {
    //         player.on('play', function() {
    //           console.log('Video is playing');
    //         });
    //       });

    // }


    // useEffect(() => {
    //     const iframe = document.getElementById('123')
    //     if (!iframe) return;



    //     const player = new Player(iframe);

    //     console.log('useEffect play');

    //     const handlePlay = () => {
    //         console.log('Played the video', lesson_number, module_number);
    //         setViewLesson(user_id, lesson_number, module_number);
    //     };

    //     // player.on('play', handlePlay);
    //     player.on('ready', function() {
    //         player.on('play', function() {
    //           console.log('Video is playing');
    //         });
    //       });

    //     // player.getVideoTitle().then((title) => {
    //     //     console.log('title:', title);
    //     // });

    //     // Clean up
    //     return () => {
    //         player.off('play', handlePlay);
    //     };
    // }, [selectedLesson]);


    return (
        <>
            <div className="section-class-center">
                <div className="section-class-center-vnutr" id="section-class-modul1">
                    {/* <p className="section-class-module"><span>Module 1:</span> Introduction to Carbon Neutrality</p>
                    <p className="section-class-lesson">Lesson 4: Implementing Carbon Neutrality in Organizations</p>
                    <div className="class-1-video">
                        
                        <iframe className="class-video-1" src="https://player.vimeo.com/video/942122829?h=1c7076c21d" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div> */}
                    <p className="section-class-module"><span>{selectedLesson?.moduleName}</span></p>
                    <p className="section-class-lesson">{selectedLesson?.lessonName}</p>
                    <div className="class-1-video">
                        {/* <iframe
                            id='123'
                            ref={iframeRef}
                            title='video'
                            className="class-video-1"
                            src={`https://player.vimeo.com/video/${selectedLesson?.videoId}?h=${selectedLesson?.videoH}`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen>
                        </iframe> */}
                        <VideoPlayer
                            videoId={selectedLesson.videoId}
                            videoH={selectedLesson.videoH}
                            lesson_number={lesson_number}
                            module_number={module_number}
                            user_id={user_id}
                        />
                        {/* <iframe title='video' className="class-video-1" src={`https://player.vimeo.com/video/942122687?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> */}

                    </div>
                    <p className="section-class-center-text-0">Description</p>
                    <p className="section-class-center-text">
                        Welcome to Module 1 of the Educational Course on Carbon Neutrality and Carbon Markets. In
                        this module, we will introduce you to the concept of carbon neutrality and its significance
                        in mitigating the impactof climate changeand its impact on the environment, society, and
                        economy. <br /><br />Within the framework of this module, we will touch on the following
                        lessons:<br /><br /> ●
                        Lesson 1: Introduction to carbon neutrality and climate change <br />● Lesson 2: Understanding
                        Carbon Neutrality <br />● Lesson 3: The Importance of Carbon Neutrality <br />● Lesson 4:
                        Implementing
                        Carbon Neutrality in Organizations <br /><br />Climate change is one of the biggest challenges
                        of our
                        time, and its effects are being felt across the globe. The increase in global temperatures
                        caused by the emission of greenhouse gases, primarily carbon dioxide, is causing a wide
                        range of problems, from rising sea levels to more extreme weather events. <br /><br />Carbon
                        neutrality
                        refers to the balance between the amount of carbon emitted and the amount removed from the
                        atmosphere. Achieving carbon neutrality is essential in reducing the impact of climate
                        change. <br /><br />In this module, we will explore the importance of carbon neutrality and its
                        role in
                        addressing the global climate crisis. We will examine successful carbon neutrality
                        initiatives in various sectors, and discuss the steps that individuals, organizations, and
                        governments can take to contribute to a carbon-neutral future.
                    </p>
                    <p className="section-class-center-text-0">Presentation</p>
                    <iframe title='doc' src={`https://docs.google.com/document/d/${selectedLesson?.gid}/preview`} width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Загрузка…</iframe>

                    <div className="blok-button-class blok-button-class-2">
                        <div className="button button-header button-class" onClick={handlePastLesson} >Past Lesson</div>
                        <div className="button button-header button-class" onClick={handleNextLesson}  >Next Lesson</div>
                    </div>
                </div>

            </div>
            <div className="section-class-right">
                <p className="section-class-right-title">Progress Lesson</p>

                {modules.map(module => (
                    <Module
                        key={module.id}
                        title={`Module ${module.number}: ${module.name}`}
                        lessons={module.lessons.map(lesson => ({
                            name: lesson.name,
                            videoId: lesson.video_id,
                            videoH: lesson.video_h,
                            number: lesson.number,
                            gid: lesson.gid
                        }))}
                        module_number={module.number}
                        progressIcons={progressIcons}
                        onLessonClick={handleLessonClick}
                    />
                ))}

                {/* тут должен быть список из <Module */}
                {/* Этот элемент представляет собой модуль, в него нужно передать lessons */}
                {/* <Module title={moduleTitle} lessons={lessons} progressIcons={progressIcons} />
                
                <div className="class-progress-modul" id="progress-modul-2">
                    <div className="class-progress-modul-title">
                        <div className="class-progress-modul-title-left">
                            <strong>Module 2: </strong>Carbon Footprint and Emissions Reduction
                            <img src={ProgressIcon4} alt="icon" />
                        </div>
                        <div className="class-progress-modul-title-right">
                            <img className="progress-open" src={ProgressIcon2} alt="icon" />
                        </div>
                    </div>
                    <div className="class-progress-modul-body"></div>
                </div>

                <div className="class-progress-modul" id="progress-modul-3">
                    <div className="class-progress-modul-title">
                        <div className="class-progress-modul-title-left">
                            <strong>Module 3: </strong>Carbon Markets and Offsets
                            <img src={ProgressIcon4} alt="icon" />
                        </div>
                        <div className="class-progress-modul-title-right">
                            <img className="progress-open" src={ProgressIcon2} alt="icon" />
                        </div>
                    </div>
                    <div className="class-progress-modul-body"></div>
                </div> */}
            </div>
        </>
    );
}

export default ClassRoom;


{/* <div className="classroom-container" style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <div className="video-container" style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Видео контейнер */}
//   <div className="video-player" style={{ flex: 1 }}>
//     <iframe
//       width="100%"
//       height="100%"
//       src="https://www.youtube.com/embed/akQx7G1d3g4"
//       title="YouTube video player"
//       frameborder="0"
//       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//       allowfullscreen
//     ></iframe>
//   </div>
// </div>
// <ModuleNavigation modules={modules} />
// <div className="info-container" style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
//   {/* Текстовая информация */}
//   <h2>Text Information</h2>
//   <p>Some text goes here...</p>
// </div> */}
{/* </div> */ }


// const modules = [
//   {
//     id: 1,
//     name: 'Module 1',
//     lessons: [
//       { id: 1, name: 'Lesson 1' },
//       { id: 2, name: 'Lesson 2' }
//     ]
//   },
//   {
//     id: 2,
//     name: 'Module 2',
//     lessons: [
//       { id: 3, name: 'Lesson 1' },
//       { id: 4, name: 'Lesson 2' }
//     ]
//   },
//   // Другие модули
// ];
{/* <iframe className="class-video-1"
                            src="https://player.vimeo.com/video/930950795?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            title="Lesson test"></iframe> */}