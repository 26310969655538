import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player'; // Импортируйте библиотеку Vimeo Player
import { setViewLesson } from '../Api/course'
const VideoPlayer = ({ videoId, videoH, lesson_number, module_number, user_id }) => {
    const iframeRef = useRef(null);

    const handlePlay = () => {
        console.log('Played the video', lesson_number, module_number);
        setViewLesson(user_id, lesson_number, module_number);
    };

    useEffect(() => {
        // const iframe = iframeRef.current;
        const iframe = document.getElementById('frame_video')
        const player = new Player(iframe);

        console.log('useEffect play', player.element);

        

        player.on('play', handlePlay);

        // return () => {
        //     player.off('play', handlePlay);
        // };
    }, [videoId, videoH, lesson_number, module_number, user_id]);

    return (
        <>
            <div onClick={() => {
                console.log('test')
                const iframe = document.getElementById('frame_video')
                const player = new Player(iframe);

                console.log('useEffect play', player.element);

                // const handlePlay = () => {
                //     console.log('Played the video', lesson_number, module_number);
                //     setViewLesson(user_id, lesson_number, module_number);
                // };

                player.on('play', handlePlay);
            }}>test</div>
            <iframe
                id='frame_video'
                ref={iframeRef}
                title='video'
                className="class-video-1"
                src={`https://player.vimeo.com/video/${videoId}?h=${videoH}`}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                key={`${videoId}-${videoH}`}
            >
            </iframe>
        </>
    );
};

export default VideoPlayer;
