import React, { useEffect, useState } from 'react';
import { getUsers, updateUserData } from '../Api/course'; // Импортируйте функцию для обновления пользователя
import { useSelector } from 'react-redux';
import { selectUserId } from '../Login/loginSlice';

function AdminRoom() {
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const userId = useSelector(selectUserId);

    useEffect(() => {
        async function fetchUsers() {
            const response = await getUsers(userId);
            setUsers(response);
        }

        fetchUsers();
    }, [userId]);

    const handleEditClick = (user) => {
        setEditingUser(user);
        setEditedName(user.first_name);
        setEditedEmail(user.email);
    };

    const handleSaveClick = async () => {
        const updatedUser = { ...editingUser, first_name: editedName, email: editedEmail };
        await updateUserData(editedEmail, editedName, '', updatedUser.id );
        setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
        setEditingUser(null);
    };

    const handleCancelClick = () => {
        setEditingUser(null);
    };

    return (
        <>
            <div className="section-class-center">
                <div className="section-class-center-vnutr">
                    <div className="section-class-faq-contant">
                        <p className="faq-title">Users</p>
                        <div className="settings-blok-table" style={{ marginTop: 30 }}>
                            <table className="user-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Code</th>
                                        <th>Module/Lesson</th>
                                        <th style={{width: '100px'}}>Pay date</th>
                                        <th>Sum</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.first_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.code}</td>
                                            <td>{user.module}/{user.lesson}</td>
                                            <td>{user.paydate ? user.paydate.slice(0, 10): ''}</td>
                                            <td>{user.sum}</td>
                                            <td>
                                                <button onClick={() => handleEditClick(user)}>Edit</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {editingUser && (
                <div className="edit-form">
                    <h2>Edit User</h2>
                    <label>
                        Name:
                        <input
                            type="text"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            value={editedEmail}
                            onChange={(e) => setEditedEmail(e.target.value)}
                        />
                    </label>
                    <button onClick={handleSaveClick}>Save</button>
                    <button onClick={handleCancelClick}>Cancel</button>
                </div>
            )}
        </>
    );
}

export default AdminRoom;
